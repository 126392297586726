import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import Img from "gatsby-image"
import { HeroSectionWrapper, CenterContent } from "./HeroSection.styles"
import { Container, Row, Col } from "react-bootstrap"
import { colors } from "../../../utils/styles"
import ApButton from "../../Global/Common/ApButton/ApButton"
import { navigateTo } from "../../../utils/hooks"
import loadable from "@loadable/component"
import { LINKS } from "../../../data/pageLinks.data"
import GlobalLeadingBrands from "./GlobalLeadingBrands/GlobalLeadingBrands"

const TextTransition = loadable(() => import("react-text-transition"))

const HeroSection = ({ index }) => {
  const heroImage = useStaticQuery(graphql`
    {
      allFile(
        filter: {
          relativeDirectory: { eq: "homepage/Hero" }
          name: { eq: "hero-image" }
        }
      ) {
        edges {
          node {
            childImageSharp {
              fluid(quality: 75, maxWidth: 1800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  const { secondaryBlue2, secondaryBlue } = colors
  const colorOptions = [secondaryBlue2, "#45B8F1"]

  const TEXTS = ["Growth", "New Markets", "Your Customers", "The World"]

  return (
    <HeroSectionWrapper className="d-flex flex-column justify-content-center position-relative">
      <div className="content p-4 p-lg-3 hero-container">
        <CenterContent
          animatedTextColor={colorOptions[index % colorOptions.length]}
        >
          <Row
            animatedTextColor={colorOptions[index % colorOptions.length]}
            className="d-flex flex-row justify-content-between mt-lg-4 pt-lg-5"
          >
            <Col className="lhs mt-5" xs={12} lg={6} lg={{ offset: 1 }}>
              <h1 className="text-uppercase text-white ambit font-weight-bold">
                Integrate to
                <br />
                <span>
                  <TextTransition text={TEXTS[index % TEXTS.length]} />
                </span>
              </h1>
              <p className=" text-white py-4">
              A Single Integration to APEXX gives you the flexibility to build your perfect Payment Orchestration Platform
              </p>
              <Link to={LINKS.contactUs}>
                <ApButton role="link" variant="lightGreen" className="learn-more-button">Learn More</ApButton>
              </Link>
            </Col>
            <Col className="rhs pt-5 pt-lg-0" xs={12} lg={6}>
              <div className="transactions-card-wrapper">
                {/* <ClubbedImages /> */}
                <Img
                  fluid={
                    heroImage.allFile.edges[0] &&
                    heroImage.allFile.edges[0].node.childImageSharp.fluid
                  }
                  className="hero-image ml-xl-5"
                />
              </div>
            </Col>
          </Row>
        </CenterContent>
      </div>

      <GlobalLeadingBrands />

    </HeroSectionWrapper>
  )
}

export default React.memo(HeroSection)
