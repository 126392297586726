import styled from "styled-components";

export const CaseStudyBannerWapper = styled.section`
    background: linear-gradient(180deg, rgba(143, 197, 234, 0.3) 0%, rgba(110, 247, 218, 0.3) 100%);

    .container{
        display: flex;
        flex-direction: row;
        gap: 20px;
        justify-content: start;
        align-items: center;
        flex-wrap: wrap;
    }

    h2{
        color: #144056;
    }

    a.tagline {
        text-decoration: none;
        font-size: 1.125rem;
        line-height: 1.5rem;
        color: #144056;
        position: relative;
    }
`