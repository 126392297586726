import React from "react";
import { Container, Col } from "react-bootstrap";
import { CaseStudyBannerWapper } from "./CaseStudyBanner.styles";
import ApButton from "../../Global/Common/ApButton/ApButton";
import { Link } from "gatsby"
import { LINKS } from "../../../data/pageLinks.data"

export const CaseStudyBanner = () => {

    return(
        <CaseStudyBannerWapper className="py-5 px-3 mb-3 my-lg-0">
            <Container className="">
                <h2 className="h2 mb-0 ambit font-weight-bold">Case Study:</h2>
                <a href={LINKS.caseStudies} className="m-0 pt-1 tagline mr-lg-auto" target="_blank">How is Payment Orchestration benefitting Enterprise Merchants?</a>
                <ApButton
                    className="font-weight-bold btn text-white"
                    variant="greenSecondaryLarge"
                    as={Link}
                    target="_blank"
                    to={LINKS.caseStudies}
                >
                    Read More
                </ApButton>
            </Container>
        </CaseStudyBannerWapper>
    );
};