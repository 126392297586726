import styled from "styled-components"
import { colors } from "../../../utils/styles"

export const StyledFeaturedSection = styled.section`
  background: linear-gradient(180.34deg, #144056 1.48%, #428AC9 140.7%);
  @media (min-width: 992px) {
    color: ${colors.primaryBlue};
  }
  @media (max-width:992px) {
    .h1 {
        font-size: 2rem;
    }
  }
  position: relative;



  .lhs {
    p {
      font-size: 1.125rem;
      line-height: 1.5em;
    }
  }
  .rhs {
    @media (min-width: 768px) {
    span,
    p {
      font-size: 0.8rem;
    }
      .description {
        height: 80px;
      }
    }
  }

  .image-container{
    max-width: 40px;
  }
`
