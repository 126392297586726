import React from "react"
import { StyledGlobalPartnerSection } from "./GlobalPartnerSection.styles"
import { Container, Row, Col } from "react-bootstrap"
import Img from "gatsby-image"
import ImagesWithReflection from "./ImagesWithReflection"
import CreditCardDemo from "./CreditCardDemo"
import { useStaticQuery, graphql } from "gatsby"

const GlobalPartnerSection = () => {
  const phoneImage = useStaticQuery(graphql`
    {
      file(relativeDirectory: {eq: "cardDemo"}, name: {eq: "credit-card-demo"}) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  
  return (
    <StyledGlobalPartnerSection className="py-3 pb-5 py-sm-5 px-4 px-lg-0 mt-lg-5">
      <Container className="my-0 my-lg-5">
        <Row className="px-3">
          <Col lg={7} className="d-flex flex-column px-0 pr-5">
            <h2 className="h1 py-2 py-lg-0 ambit font-weight-bold mb-lg-4 mt-lg-5">The Global Partner for Local Payments</h2>
            <p className="py-2 py-lg-0 mb-4 mb-lg-0">
            Our vision is to be the payment industry’s most merchant centric provider. Through our platform a merchant
            can connect via a simple API connection to the world’s payment ecosystem.
            </p>
          </Col>
          <Col className="d-none d-lg-block px-0">
            <Img fluid={phoneImage.file.childImageSharp.fluid} className='w-100'/>
          </Col>
        </Row>
      </Container>
    </StyledGlobalPartnerSection>
  )
}

export default GlobalPartnerSection
