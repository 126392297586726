import styled from "styled-components"
import { colors } from "../../../utils/styles"

export const StyledHowDoesSection = styled.section`
  background: linear-gradient(180deg, rgba(116, 151, 237, 0.3) 0%, rgba(85, 186, 164, 0.3) 100%);

  p{
    font-size: 1.125rem;
    line-height: 1.675;
  }
`
