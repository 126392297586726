import React, { useState, useEffect } from "react"
import SEO from "../components/Global/SEO/seo"
import Layout from "../components/Global/Layout/Layout"
import HeroSection from "../components/HomePage/HeroSection/HeroSection"
import FeaturesSection from "../components/HomePage/FeaturesSection/FeaturesSection"
import HowDoesSection from "../components/HomePage/HowDoesSection/HowDoesSection"
import AwardsSection from "../components/HomePage/AwardsSection/AwardsSection"
import GlobalPartnerSection from "../components/HomePage/GlobalPartnerSection/GlobalPartnerSection"
import GlobalIntegrationSection from "../components/HomePage/GlobalIntegrationSection/GlobalIntegrationSection"
import LogosSlider from "../components/HomePage/LogosSlider/LogosSlider"
import { useViewportScroll } from "framer-motion"
import { CaseStudyBanner } from "../components/HomePage/CaseStudyBanner/CaseStudyBanner"

const IndexPage = () => {
  const { scrollYProgress } = useViewportScroll()
  const [index, setIndex] = useState(0)
  useEffect(() => {
    const intervalId = setInterval(
      () => {
        setIndex((index) => index + 1)
      },
      3000 // every 3 seconds
    )
    return () => {
      clearInterval(intervalId)
    }
  }, [])

  return (
    <Layout
      headerTransparent
      whiteToggler
      logoDark={false}
      helpSectionColor={'white-bg'}
    >
      <SEO page="home"/>
      <HeroSection index={index} />
      {/* <LogosSlider /> */}
      <CaseStudyBanner/>
      <GlobalPartnerSection />
      <GlobalIntegrationSection
        scrollYProgress={scrollYProgress}
        index={index}
      />
      <FeaturesSection />
      <HowDoesSection />
      {/* <AwardsSection /> */}
    </Layout>
  )
}

export default IndexPage
