import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { StyledFeaturedSection } from "./FeaturesSection.styles"
import { Container, Col, Row } from "react-bootstrap"
import { FeaturesSectionData } from "../../../data/homePage.data"
import FeatureCard from "./FeatureCard"
import HeadingWithBg from "../../Global/Common/HeadingWithBg/HeadingWithBg"
import { LINKS } from "../../../data/pageLinks.data"

const FeaturesSection = () => {
  const data = useStaticQuery(graphql`
    {
      allImageSharp(filter: {fluid: {originalName: {regex: "/feature_/"}}}, sort: {fields: fluid___originalName, order: ASC}) {
        nodes {
          id
          fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  const {
    allImageSharp: { nodes },
  } = data

  return (
    <StyledFeaturedSection className="p-4 align-items-center">
      <Container className="mt-lg-5 mb-5">
        <Row>
          <Col className="d-flex flex-column lhs pr-3 payment-orchestration-header" xs={12} lg={6}>
            <HeadingWithBg className="mb-5 text-white" text="Features" />
            <div className="mt-lg-5">
              <h2 className="h1 mb-5 ambit font-weight-bold color-sky-blue">
                A Payment Orchestration Platform for Enterprise Merchants
              </h2>
              <p className="text-white">
                Our vision is to be the payment industry's most merchant centric
                provider. Through our platform a merchant can connect via a
                simple API connection to the world's payment ecosystem.
              </p>
            </div>
          </Col>
          <Col className="pr-lg-0 pt-5 px-4 pl-lg-5" xs={12} lg={6}>
            <div className="rhs">
              <Row>
                <FeatureCard
                  feature={FeaturesSectionData[0]}
                  imageData={nodes[0]}
                  className = "pt-4"
                  url = {LINKS.gateway}
                />
                <FeatureCard
                  feature={FeaturesSectionData[1]}
                  imageData={nodes[1]}
                  className = "pt-4"
                  url = {LINKS.partnerships}
                  />
                <FeatureCard
                  feature={FeaturesSectionData[2]}
                  imageData={nodes[2]}
                  className = "pt-4"
                  url = {LINKS.dynamicRouting}
                />
                <FeatureCard
                  feature={FeaturesSectionData[3]}
                  imageData={nodes[3]}
                  className = "pt-4"
                  url = {LINKS.apm}
                />
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </StyledFeaturedSection>
  )
}

export default FeaturesSection
