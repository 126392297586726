import styled from "styled-components"

export const StyledLogosSlider = styled.section`
  background-color: #f4f4f4;
  .slider-container {
    width: 100vw;
    overflow-x: scroll;
    white-space: nowrap;
    display: flex;
    -ms-overflow-style: none;
    scrollbar-width: none;
    align-items: center;
    p{
      padding-left: 150px;
      margin: 0;
      img{
        max-width: 150px;
        max-height: 40px;
      }
    }
    .scroll-disabler {
      width: 100vw;
      height: 34.4px;
      position: absolute;
      background-color: rgba(0,0,0 , 0.0001);
    }

    ::-webkit-scrollbar {
      display: none;
    }
  }


`
