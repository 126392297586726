import React from "react"
import { Col } from "react-bootstrap"
import Img from "gatsby-image"

const FeatureCard = ({ feature, imageData, className, url }) => {
  const { heading, description } = feature
  return (
    <Col className={` ${className} mb-3 mb-lg-0`} md={6}>
      <div className="d-flex flex-column align-items-left mb-4 h-100 ">
        <div className="mb-4 w-25 image-container">
          <Img fluid={imageData.fluid} className="w-100 fs-img" />
        </div>
        <div className="pb-2">
          <h5 className="pb-1 text-white ambit font-weight-bold mb-3">{heading}</h5>
          <p className="text-white">{description}</p>
          <span className="mb-3 text-white">
            &ndash;&nbsp;
            <a href={url} className="secondary-blue-2-text">
              Learn More
            </a>
          </span>
        </div>
      </div>
    </Col>
  )
}

export default FeatureCard
