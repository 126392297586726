import React from "react"
import { StyledHowDoesSection } from "./HowDoesSection.styles"
import ApButton from "../../Global/Common/ApButton/ApButton"
import { Container, Row, Col } from "react-bootstrap"
import Map from "../Map/Map"
import { Link } from "gatsby"
import { LINKS } from "../../../data/pageLinks.data"

const HowDoesSection = () => {
  return (
    <StyledHowDoesSection className="py-5 px-3">
      <Container className="my-5">
        <Row className="py-md-4">
          <Col md={6} xs={12} className="d-flex">
            <h2 className="h1 primary-blue-text ambit font-weight-bold mr-lg-5 mt-md-5">How does a Payment Orchestration Platform work?</h2>
          </Col>
          <Col md={6} xs={12} className="mt-3 mt-md-0 pl-lg-5">
            <p className="">
            The goal of any payment solution is a simple one: maximise conversion
            rates and limiting costs whilst making life as easy as possible for
            our merchants and their customers. This can be achieved by integrating
            the optimum providers globally through a single provider that will
            continue to audit your set-up to futureproof your payments stack.
            </p>
          <ApButton
            className="mt-3 btn font-weight-bold"
            as={Link}
            to={LINKS.paymentOrchestration}
            variant="greenSecondary"
          >
            Find Out More
          </ApButton>
        </Col>
        </Row>
        
        
      </Container>
    </StyledHowDoesSection>
  )
}

export default HowDoesSection
