import styled from "styled-components"

export const StyledCardDemoWrapper = styled.div`
  box-shadow: -5px 3px 12px -4px rgba(0, 0, 0, 0.53);
  border-radius: 10px;
  position:relative;
  width:400px;
  .buttons{
    margin-left:10px;
    width:100%;
    position:absolute;
    span{
     border:1px solid #979797;
      font-size:5px;
      padding:1px 4px;;
      margin-right:10px;
      border-radius:10px;
      &:first-child{
        background:#979797;
      }
    }
    .cd-img{
      width: 100px;
    margin-right: 13px;
    margin-top: 5px;
    }
  }
  .bar{        
background: rgba(216, 216, 216, 0.4);
border-radius: 3.5px;
height:15px;
width:150px;
&:last-child{
  height:10px;
  margin-top:10px;
  width:100px
}
      }
  .header-section {
    border-radius: 10px;
    .card-image {
      margin-top:30px;
      .cd-img {
        min-width: 150px;
      }
    }
    .info {
      h1 {
        font-size: 1.2rem;
      }
      .cd-img {
        width: 100px;
      }
    }
  }
  .main {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    .card-options {
      &__option {
        .dot {
          height: 15px;
          width: 15px;
          border: 1px solid #979797;
          background-color: #ffffff;
          border-radius: 50%;
          display: inline-block;
        }
        .option {
          padding-left: 10px;
          font-size:10px;
        }
        &:last-child {
          .dot {
            background-color: #78dac5;
          }
        }
      }
    }
  }
  .form-group {
    padding: 5px;
    label {
      color: #686868;
      font-size: 12px;
    }
    input {
      border-color: rgb(151, 151, 151, 0.5);
      background-color:#ffffff;
      ::placeholder {
        color: rgb(104, 104, 104, 0.4);
      }
    }
  }
  .cardNumber {
    grid-area: cardNumber;
  }
  .expDate {
    grid-area: expDate;
  }
  .holderName {
    grid-area: holderName;
  }
  .cvv {
    grid-area: cvv;
  }
  .bb {
    grid-area: bb;
    button{
      border-radius:10px;
    }
  }
  .demo-form {
    display: grid;
    grid-template-columns: 210px 88px;
    grid-template-areas:
      "cardNumber  expDate"
      "holderName  cvv"
      "bb  bb";
  }
`
