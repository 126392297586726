import React from "react";
import { Col, Container, Row } from "react-bootstrap"
import { GlobalLeadingBrandsWrapper } from "./GlobalLeadingBrands.styles";
import { useStaticQuery, graphql } from "gatsby"

const GlobalLeadingBrands = () => {

    const data = useStaticQuery(graphql`
    {
      allFile(filter: {relativeDirectory: {eq: "homepage/leading_brands"}}) {
        edges {
          node {
            childImageSharp {
              fluid(quality: 75, maxHeight: 100) {
                ...GatsbyImageSharpFluid
              }
            }
            name
          }
        }
      }
    }
  `)

    const imageItems = {};    

    data.allFile.edges.map(item =>{
        imageItems.[item.node.name] = item;
    });

    return (
    <GlobalLeadingBrandsWrapper>
        <Container>
          <Row className="justify-content-center">
            <h2 className="h4 ambit pt-lg-0 pt-3 text-center">Trusted by leading global brands:</h2>
          </Row>
          <Row className="justify-content-center logos-container mt-4 w-100 px-0">
            <div class="d-flex align-items-center">
              <img className="mw-100 my-2" src={imageItems.esw.node.childImageSharp.fluid.src} alt={imageItems.esw.node.name + " logo"} />
            </div>
            <div class="d-flex align-items-center">
              <img className="mw-100 my-2" src={imageItems.avon.node.childImageSharp.fluid.src} alt={imageItems.avon.node.name + " logo"} />
            </div>
            <div class="d-flex align-items-center">
              <img className="mw-100 my-2" src={imageItems.swoon.node.childImageSharp.fluid.src} alt={imageItems.swoon.node.name + " logo"} />
            </div>
            <div class="d-flex align-items-center">
              <img className="mw-100 my-2 large-height" src={imageItems.majorTravel.node.childImageSharp.fluid.src} alt={imageItems.majorTravel.node.name + " logo"} />
            </div>
            <div class="d-flex align-items-center">
              <img className="mw-100 my-2 large-height" src={imageItems.airSeychelles.node.childImageSharp.fluid.src} alt={imageItems.airSeychelles.node.name + " logo"} />
            </div>
            <div class="d-flex align-items-center">
              <img className="mw-100 my-2 small-height" src={imageItems.ryanAir.node.childImageSharp.fluid.src} alt={imageItems.ryanAir.node.name + " logo"} />
            </div>
            <div class="d-flex align-items-center">
              <img className="mw-100 my-2 medium-height" src={imageItems.carTrawler.node.childImageSharp.fluid.src} alt={imageItems.carTrawler.node.name + " logo"} />
            </div>
            <div class="d-flex align-items-center">
              <img className="mw-100 my-2 xl-height" src={imageItems.Internova.node.childImageSharp.fluid.src} alt={imageItems.Internova.node.name + " logo"} />
            </div>
          </Row>
        </Container>
    </GlobalLeadingBrandsWrapper>
)};

export default GlobalLeadingBrands