import styled from "styled-components"
import { colors } from "../../../utils/styles"

export const StyledGlobalPartnerSection = styled.section`
  
  color: ${colors.primaryBlue};
  .credit-card {
    &:first-child {
      min-width: 350px;
    }
  }
  .lhs {
    color: #55baa4;
  }
  .green-check {
    width: 80px;
    border-radius: 50%;
    box-shadow: 7px 14px 22px rgba(126, 138, 136, 0.5);
  }
  .rhs {
    justify-content: space-evenly;
  }
  .payment-complete {
    box-shadow: -5px 3px 12px -4px rgba(0, 0, 0, 0.53);
    border-radius: 10px;
    width: 190px;
  }
  p{
    font-size: 1.25rem;
    line-height: 1.875rem;
  }

  h2{
    @media (min-width: 992px){
      font-size: 3rem;
    }
  }
`
