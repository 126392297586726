import styled from "styled-components";

export const GlobalLeadingBrandsWrapper = styled.section`
    position: relative;
    z-index: 120;
    background-color: white;
    width: fit-content;
    max-width: calc(100% - 3rem);
    border-radius: 20px;
    margin: 3rem auto 7rem;
    box-shadow: 0 50px 50px rgba(0,0,0,0.2);
    padding: 3em clamp(2em, 6vw, 7em);

h2{
    color: #979797;
}
.logos-container{
    max-width: 1050px;
    margin: 0;
    gap: 1em 2em;

    img{
        max-height: 20px;

        /* @media only screen and (max-width: 991px) {
            max-height: 15px;
        } */

        &.xl-height{
            max-height: 30px;

            /* @media only screen and (max-width: 991px) {
                max-height: 20px;
            } */
        }
        &.large-height{
            max-height: 25px;

            /* @media only screen and (max-width: 991px) {
                max-height: 20px;
            } */
        }
        &.medium-height{
            max-height: 20px;

            /* @media only screen and (max-width: 991px) {
                max-height: 20px;
            } */
        }
        &.small-height{
            max-height: 15px;
            /* @media only screen and (max-width: 991px) {
                max-height: 13px;
            } */
        }
    }
}
`