import styled from "styled-components"

export const StyledImageWithReflection = styled.div`
  width: 20%;
  padding: 0 3px;
  -webkit-box-reflect: below -10px -webkit-gradient(
      linear,
      right top,
      right bottom,
      from(transparent),
      to(rgba(255, 255, 255, 0.4))
    );
`
