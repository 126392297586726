import styled from "styled-components"
import { colors } from "../../../utils/styles"
import { graphql, useStaticQuery } from "gatsby"

const { primaryBlue } = colors

  const BannerBgImage = () => {
    const data = useStaticQuery(graphql`
    {
    allFile(filter: {relativeDirectory: {eq: "homepage/Hero"}, name: {eq: "background-image"}}) {
      edges {
        node {
          childImageSharp {
            fluid(quality: 60, maxWidth: 2500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
    `)
    return data.allFile.edges[0].node.childImageSharp.fluid.src
  }

export const HeroSectionWrapper = styled.section`
  width: 100%;
  @media (min-width: 992px) {
    min-height: 100dvh;
    background: none;
      margin-bottom: 2rem;

      .hs-web-interactives-top-banner-open & {
          min-height: calc(100dvh - 132px);
      }
      
      .text-transition {
          min-height: 2lh;
      }
  }
  @media (max-width: 1200px) {
    max-height: 1000px;
  }
  @media (max-width: 991px) {
    max-height: none;
  }
  &::after{
    content: '';
    background-image: url(${BannerBgImage});
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    position: absolute;
    @media (min-width: 992px) {
      clip-path: polygon(0 0, 100% 0, 100% 30%, 0% 100%);
    }
    opacity: 0.25;
  }
  &::before{
    content: '';
    @media (min-width: 992px) {
      clip-path: polygon(0 0, 100% 0, 100% 30%, 0% 100%);
    }
    background: linear-gradient(180deg, #3B5CA7 -2.52%, #428AC9 95.37%);
    width: 100%;
    height: 100%;
    position: absolute;
  }
  overflow: hidden;
  .hero-container{
    @media (max-width: 991px) {
      margin-top: 75px;
    }
  }
  .map {
    opacity: 0.4;
  }
  .hero-image {
    border-radius:20px;
    @media (min-width: 992px) {
      left: 10%;
      margin-bottom: -10%;
    }
  }
  .btn {
    outline: none;
    border: none;
    @media (max-width: 992px) {
      width: 100%;
    }
    &:active {
      outline: none;
      border: none;
    }
    &:focus {
      outline: 0 !important;
      box-shadow: none !important;
    }
    &.learn-more-button{
      font-size: 1.25rem;
      line-height: 1.875rem;
    }
  }
  .logos {
    width: 160px;
    opacity: 0.7;
  }
  .content {
    width: 100vw;
    z-index: 99;
    min-width: 320px;
  }
  .map {
    position: absolute;
    min-height: calc(100% - 222px);
    top: 0;
    width: 100%;
    svg {
      opacity: 0.5;
    }
  }
  .row {
    display: flex;
    justify-content: space-between;
    margin-inline: 0;
  }
  .col-md-3 {
    max-width: 230px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`

export const CenterContent = styled.div`
  div {
    .flip {
      height: 50px;
      overflow: hidden;
      div {
        &:first-child {
          animation: show 6s linear infinite;
        }
      }
    }

    h1 {
      text-transform: uppercase;
      color: ${primaryBlue};
      @media (min-width: 992px) {
        font-size: 3rem;
      }
      @media (min-width: 1200px) {
        font-size: 4rem;
      }
      span {
        color: ${(props) => props.animatedTextColor};
        font-weight: 800;
        height: 50px;
        margin-bottom: 50px;
      }
    }
    p {
      align-self: center;
      color: ${primaryBlue};
      @media (min-width: 992px) {
        font-size: 1.25rem;
        max-width: 500px;
      }
    }
  }

  @keyframes show {
    0% {
      margin-top: -200px;
    }
    12.5% {
      margin-top: -150px;
    }
    25% {
      margin-top: -150px;
    }
    37.5% {
      margin-top: -100px;
    }
    50% {
      margin-top: -100px;
    }
    62.5% {
      margin-top: -50px;
    }
    75% {
      margin-top: -50px;
    }
    87.5% {
      margin-top: 0px;
    }
    100% {
      margin-top: 0px;
    }
  }
`
