import styled from "styled-components"

export const StyledAwardsSection = styled.section`
  .col {
    max-width: 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`
